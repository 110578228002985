<template>
  <span class="edit-account-button" v-if="canEdit">
    <a href @click.prevent="showModal" class="btn btn-sm btn-secondary" v-b-tooltip="'Modifier'">
      <i class="fas fa-edit"></i>
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      :ok-disabled="!name"
      @cancel="onCancel"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-table"></i> Édition {{ account.name }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Code" label-for="name">
            <b-form-input id="name" v-model="name" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Libellé" label-for="label">
            <b-form-input id="label" v-model="label"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasShowYearChoice">
        <b-col>
          <b-form-group label="Mode d'affichage" label-for="showYear">
            <b-select id="showYear" v-model="showYear">
              <b-select-option v-for="choice in showYearChoices" :key="choice.id" :value="choice.id">
                {{ choice.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Ordre" label-for="order">
            <b-form-input type="number" step=1 id="order" v-model="order"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasGroupChoice">
        <b-col>
          <b-form-group label="Priorité" label-for="priority">
            <b-form-input type="number" step=1 id="priority" v-model="priority"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasGroupChoice">
        <b-col>
          <b-form-group label="Groupe" label-for="group">
            <vue-bootstrap-typeahead
              v-model="groupName"
              ref="groupTypeAhead"
              :data="groups"
            >
            </vue-bootstrap-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { getAccountDisplayModeChoices } from '@/types/payments'

export default {
  name: 'edit-account-button',
  mixins: [BackendMixin],
  props: {
    account: Object,
    modelName: String,
  },
  data() {
    return {
      randomId: getRandomId(),
      name: '',
      label: '',
      showYear: 0,
      showYearChoices: [],
      groups: [],
      groupName: null,
      order: 0,
      priority: 0,
      errorText: '',
    }
  },
  computed: {
    canEdit() {
      return this.hasPerm('payments.change_' + this.modelName + 'account')
    },
    hasShowYearChoice() {
      return this.modelName === 'analytic'
    },
    hasGroupChoice() {
      return this.modelName === 'analytic'
    },
  },
  watch: {
    account: function(newValue) {
      this.setAccount(newValue)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
      this.getGroups()
      this.init()
    },
    init() {
      this.groupName = this.account.group ? this.account.group.name : ''
      setTimeout(
        () => {
          if (this.$refs.groupTypeAhead) {
            this.$refs.groupTypeAhead.inputValue = this.groupName
          }
        }, 100
      )
    },
    getId() {
      return 'bv-modal-edit-account-button' + this.randomId
    },
    async getGroups() {
      if (this.hasGroupChoice) {
        const url = '/api/' + this.modelName + '-groups/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.groups = resp.data.map(elt => elt.name)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async onSave() {
      const url = '/api/' + this.modelName + '-accounts/' + this.account.id + '/'
      const data = {
        name: this.name,
        label: this.label,
        order: +this.order,
      }
      if (this.hasShowYearChoice) {
        data['show_year'] = this.showYear
      }
      if (this.hasGroupChoice) {
        data['group'] = this.groupName
        data['priority'] = this.priority
      }
      let backendApi = new BackendApi('patch', url)
      try {
        await backendApi.callApi(data)
        await this.addSuccess('Le compte a été modifié')
        this.$emit('done', this.account)
        this.$bvModal.hide(this.getId())
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onCancel() {
      this.setAccount(this.account)
      this.$bvModal.hide(this.getId())
    },
    setAccount(account) {
      this.name = account.name
      this.label = account.label
      this.showYear = account.showYear
      this.order = account.order
      this.priority = account.priority
    },
  },
  mounted() {
    this.showYearChoices = getAccountDisplayModeChoices()
    this.setAccount(this.account)
  },
}
</script>

<style scoped>
</style>
