<template>
  <div class="financial-accounts" v-if="canView">
    <page-header :title="title" :icon="icon" :links="getLinks()">
    </page-header>
    <div>
      <b-row>
        <b-col cols="6">
          <b-form-group description="Ne voir que les comptes incluant ce texte">
            <b-form-input v-model="filterValue" type="text" placeholder="Filtrer les comptes">
            </b-form-input>
          </b-form-group>
          <b-form-checkbox id="orderByPriority" v-model="orderByPriority" v-if="isAnalytic">
            Trier par priorité
          </b-form-checkbox>
        </b-col>
        <b-col cols="6">
          <b-form-group description="Ne voir que les comptes du groupe" v-if="isAnalytic">
            <b-form-input v-model="filterGroup" type="text" placeholder="Filtrer par groupe">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="isLoaded" ref="printMe">
      <b-row>
        <b-col cols="12" ref="excelMe">
          <table class="table-full table-striped table-bordered table-accounts">
            <tr class="row-header">
              <th>Code</th>
              <th>Libellé</th>
              <th>Ordre</th>
              <th v-if="isAnalytic">Priorité</th>
              <th v-if="hasShowYearChoice">Affichage</th>
              <th>Ventes</th>
              <th v-if="isAnalytic">Groupe</th>
              <th class="no-print" style="width: 120px;"></th>
            </tr>
            <tr v-if="filteredObjects.length === 0">
              <td :colspan="isAnalytic ? 7 : 5" class="empty-text">Aucun élement</td>
            </tr>
            <tr v-for="obj of filteredObjects" :key="obj.id">
              <td>{{ obj.name }}</td>
              <td>{{ obj.label }}</td>
              <td>{{ obj.order }}</td>
              <td v-if="isAnalytic">
                <span v-if="obj.priority">{{ obj.priority }}</span>
              </td>
              <td v-if="hasShowYearChoice">
                <span class="badge badge-light">{{ obj.getDisplayMode() }}</span>
              </td>
              <td>
                <span v-for="schoolYear of schoolYears" :key="schoolYear.id" class="sales-stats">
                  <div v-if="getCount(schoolYear, obj)">
                    {{ schoolYear.name }}:
                    <span class="sales-price">{{ getPrice(schoolYear, obj) | currency }}</span>
                    <span class="sales-counter">{{ getCount(schoolYear, obj) }}</span>
                  </div>
                </span>
              </td>
              <td v-if="isAnalytic">
                <span v-if="obj.group">{{ obj.group.name }}</span>
              </td>
              <td class="text-right no-print">
                <div class="show-hover">
                  <edit-account-button :model-name="modelName" :account="obj" @done="onLoaded()">
                  </edit-account-button>
                  &nbsp;
                  <delete-account-button :model-name="modelName" :account="obj" @done="onLoaded()">
                  </delete-account-button>
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <add-account-modal
      :modal-id="addModalId"
      :model-name="modelName"
      @done="onLoaded()"
    ></add-account-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DeleteAccountButton from '@/components/Accounting/DeleteAccountButton'
import AddAccountModal from '@/components/Accounting/AddAccountModal'
import EditAccountButton from '@/components/Accounting/EditAccountButton'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { includes } from '@/utils/strings'
import { compareStrings, compareNumbers } from '@/utils/sorting'

export default {
  name: 'AnalyticAccounts',
  mixins: [BackendMixin],
  components: {
    AddAccountModal,
    DeleteAccountButton,
    EditAccountButton,
    LoadingGif,
    PageHeader,
  },
  props: {
    modelName: String,
  },
  data() {
    return {
      objects: [],
      loadingName: 'financialAccountsLoading',
      addModalId: 'bv-modal-add-financial-account',
      stats: new Map(),
      filterValue: '',
      filterGroup: '',
      schoolYears: [],
      orderByPriority: false,
    }
  },
  watch: {
    modelName: function() {
      this.onLoaded()
    },
  },
  computed: {
    isAnalytic() {
      return this.modelName === 'analytic'
    },
    isGeneral() {
      return this.modelName === 'general'
    },
    title() {
      return this.isAnalytic ? 'Comptes analytiques' : 'Comptes généraux'
    },
    icon() {
      return 'fas fa-table'
    },
    isLoaded() {
      return !this.isLoading(this.loadingName)
    },
    canView() {
      if (this.isAnalytic) {
        return this.hasPerm('payments.view_analyticaccount')
      } else if (this.isGeneral) {
        return this.hasPerm('payments.view_generalaccount')
      }
      return false
    },
    hasShowYearChoice() {
      return this.modelName === 'analytic'
    },
    filteredObjects() {
      let objects = this.objects
      if (this.filterValue) {
        objects = objects.filter(
          elt => includes(elt.name, this.filterValue) || includes(elt.label, this.filterValue)
        )
      }
      if (this.isAnalytic && this.filterGroup) {
        objects = objects.filter(
          elt => elt.group && includes(elt.group.name, this.filterGroup)
        )
      }
      return objects.sort(
        (obj1, obj2) => {
          if (this.orderByPriority) {
            if (obj1.priority !== obj2.priority) {
              const priority1 = obj1.priority || 9999999
              const priority2 = obj2.priority || 9999999
              return compareNumbers(priority1, priority2)
            }
          }
          if (obj1.order !== obj2.order) {
            return compareNumbers(obj1.order, obj2.order)
          }
          return compareStrings(obj1.name, obj2.name)
        }
      )
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      this.startLoading(this.loadingName)
      await this.loadSchoolYears()
      await this.loadObjects()
      await this.loadSalesStats()
      this.endLoading(this.loadingName)
    },
    async loadObjects() {
      let url = ''
      let maker = null
      if (this.isAnalytic) {
        url = '/api/analytic-accounts/'
        maker = makeAnalyticAccount
      } else if (this.isGeneral) {
        url = '/api/general-accounts/'
        maker = makeGeneralAccount
      }
      if (maker === null) {
        return
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.objects = resp.data.map(elt => maker ? maker(elt) : elt)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        ).filter(
          elt => elt.isActive
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSalesStats() {
      this.stats = new Map()
      const url = '/api/sales-stats/' + this.modelName + '_account/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        for (const elt of resp.data) {
          const key = '' + elt['school_year'] + '-' + elt['group_by']
          this.stats.set(key, [elt.price, elt.count])
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    addAccount() {
      this.$bvModal.show(this.addModalId)
    },
    getCount(schoolYear, obj) {
      const key = '' + schoolYear.id + '-' + obj.id
      if (this.stats.has(key)) {
        return this.stats.get(key)[1]
      }
      return null
    },
    getPrice(schoolYear, obj) {
      const key = '' + schoolYear.id + '-' + obj.id
      if (this.stats.has(key)) {
        return this.stats.get(key)[0]
      }
      return null
    },
    getLinks() {
      const links = []
      links.push(
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      links.push(
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      if (this.hasPerm('payments.add_' + this.modelName + 'account')) {
        links.push(
          {
            id: 1,
            label: 'Ajouter',
            callback: this.addAccount,
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = this.modelName + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = this.modelName + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">
.sales-stats {
  display: block;
  font-size: 13px;
  margin-bottom: 2px;
}
.sales-counter {
  margin: 0 2px;
  padding: 1px 5px;
  border: solid 1px #888;
  border-radius: 4px;
  font-size: 12px;
}
.table-accounts td, .table-accounts th {
  padding: 5px;
}
tr td .show-hover {
  display: none;
}
tr:hover td .show-hover {
  display: block;
}
.table-accounts tr:hover td {
  background: #ccc !important;
}
.table-full {
  width: 100%;
}
</style>
