<template>
  <span class="delete-account-button" v-if="canDelete">
    <a href @click.prevent="showModal" class="btn btn-sm btn-danger" v-b-tooltip="'Supprimer'">
      <i class="fas fa-trash-alt"></i>
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onDelete"
      ok-variant="primary"
      cancel-title="Non"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-table"></i> Êtes vous sûr de vouloir supprimer le compte {{ account.name }}?</b>
      </template>
      <b-row>
        <b-col>
          Attention! Ce compte sera archivé et ne sera ensuite plus disponible
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'delete-account-button',
  mixins: [BackendMixin],
  props: {
    account: Object,
    modelName: String,
  },
  data() {
    return {
      randomId: getRandomId(),
    }
  },
  computed: {
    canDelete() {
      return this.hasPerm('payments.delete_' + this.modelName + 'account')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-delete-account-button' + this.randomId
    },
    async onDelete() {
      const url = '/api/' + this.modelName + '-accounts/' + this.account.id + '/'
      let backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('Le compte a été supprimé')
        this.$emit('done', this.account)
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.$bvModal.hide(this.getId())
    },
  },
}
</script>

<style scoped>
</style>
